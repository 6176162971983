<template>
  <div class="history-kpis-list-page w-100">
    <basic-subheader title="Lịch sử dữ liệu input các KPIs"> </basic-subheader>
    <b-container fluid class="history-kpis-list-page__body mb-6 mt-6">
      <basic-form-tab :submitButton="false" ref="formTab">
        <glucose-statistic-list></glucose-statistic-list>
        <hba1c-statistic-list></hba1c-statistic-list>
        <blood-pressure-statistic-list></blood-pressure-statistic-list>
        <weight-statistic-list></weight-statistic-list>
        <exercise-statistic-list></exercise-statistic-list>
        <diet-statistic-list></diet-statistic-list>
        <emotion-statistic-list></emotion-statistic-list>
      </basic-form-tab>
    </b-container>
  </div>
</template>

<script>
import GlucoseStatisticList from './glucoseStatisticList';
import Hba1cStatisticList from './hba1cStatisticList';
import BloodPressureStatisticList from './bloodPressureStatisticList';
import WeightStatisticList from './weightStatisticList';
import ExerciseStatisticList from './exerciseStatisticList';
import DietStatisticList from './dietStatisticList';
import EmotionStatisticList from './emotionStatisticList';

export default {
  components: {
    GlucoseStatisticList,
    Hba1cStatisticList,
    BloodPressureStatisticList,
    WeightStatisticList,
    ExerciseStatisticList,
    DietStatisticList,
    EmotionStatisticList,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
